import React, { useContext, useEffect } from 'react'

import {
  UiTheme,
  useMultiUiBrandContext,
  ProgressIndicator
} from '@jarvis/react-setup-and-onboarding-addons'
import { ConfigContext } from '@/store'

import classes from './LoadingBackdrop.module.scss'

export default function LoadingBackdrop() {
  const { setBackgroundClassName } = useContext(ConfigContext)
  const { uiBrand } = useMultiUiBrandContext()
  const isHPX = uiBrand === UiTheme.hpx

  useEffect(() => {
    setBackgroundClassName(classes.backgroundContainer)
    return () => {
      setBackgroundClassName(null)
    }
  }, [])

  return (
    <div
      data-testid="container-loading"
      className={classes.loadingContainer}
      hidden
    >
      <ProgressIndicator
        data-testid="spinner"
        size={isHPX ? 'large' : 'small'}
        appearance="circular"
        behavior="indeterminate"
      />
    </div>
  )
}
